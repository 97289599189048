import React from "react";
import "./TheProject1.css";
import { Fade } from "react-awesome-reveal";

function TheProject1() {
  return (
    <section className="the-project bg-blue">
      <div className="container-sm">
        <h2 className="the-project-heading text-xxl text-center">
          The Project
        </h2>
        <Fade direction="left" triggerOnce={true}>
          <div className="p1-row1">
            <div className="left">
              <h3 className="row-heading">Website 1.0</h3>
              <p className="row-text">
                The first version of the website focused on simplicity and clear
                communication of the overall concept, targeting both customers
                and users.
              </p>
            </div>
            <div className="right">
              <video loop autoPlay muted width="100%">
                <source src="images/inklusiv1.mov" type="video/mp4" />
              </video>
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p1-row2">
            <div className="left">
              <img src="images/inklusiv2.png" width="100%" alt="inklusiv2" />
            </div>
            <div className="right">
              <h3 className="row-heading">Based on User Research</h3>
              <p className="row-text">
                The app's initial version was informed by user interviews and
                questionnaires highlighting key issues faced by people with
                disabilities in physical stores.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TheProject1;
