import Hero from "./Projects/MoveUnited/Hero";
import MyRole from "./Projects/MoveUnited/MyRole";
import TheProject2 from "./Projects/MoveUnited/TheProject2";
import GraphicDesign from "./Projects/MoveUnited/GraphicDesign";
import KeyLearnings from "./Projects/MoveUnited/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

import React from "react";

function MoveUnited() {
  return (
    <>
      <BackNext
        linkBack="/Inklusiv"
        linkNext="/Tegner"
        back="Inklusiv."
        next="Tegner"
      />
      <Hero />
      <MyRole />
      <TheProject2 />
      <GraphicDesign />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default MoveUnited;
