import React from "react";
import "../MyRole.css";

function MyRole() {
  return (
    <section className="role">
      <div className="container-sm">
        <div className="role-content">
          <h2 className="role-heading text-xxl text-center">My Role</h2>
          <p className="role-text">
            I created illustrations, animations, and other visual content, and
            participated in user testing, expert interviews, and developing
            auditory content.
          </p>
          <p className="project-tags">
            Credits to Mille Skou and Rudolph Tegner's Museum and Statue Park
          </p>
        </div>
      </div>
    </section>
  );
}

export default MyRole;
