import React from "react";
import "../MyRole.css";

function MyRole() {
  return (
    <section className="role">
      <div className="container-sm">
        <div className="role-content">
          <h2 className="role-heading text-xxl text-center">My Role</h2>
          <p className="role-text">
            I helped plan the design process, mapped the philosophical approach,
            and conducted exploratory data collection.
          </p>
          <p className="project-tags">Credits to Jonas Pedersen and AIR Lab</p>
        </div>
      </div>
    </section>
  );
}

export default MyRole;
