import React from "react";
import "../KeyLearnings.css";

function KeyLearnings() {
  return (
    <section className="learnings bg-grey2">
      <div className="container-md">
        <h2 className="learnings-header text-xl">Key Learnings</h2>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#1</font>&ensp; Designed non-obtrusive
          interfaces for sensitive environments. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#2</font>&nbsp; Balanced the needs of the
          museum, visitors, and design goals. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#3</font>&nbsp; Iteratively developed auditory
          content along with the user interface. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#4</font>&nbsp; Explored how locative sound
          enhances visitor engagement. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#5</font>&nbsp; Minimized visual distractions to
          focus on the physical surroundings. <br />
        </p>
      </div>
    </section>
  );
}

export default KeyLearnings;
