import React from "react";
import "./TheProject3.css";
import { Fade } from "react-awesome-reveal";

function TheProject3() {
  return (
    <section className="the-project bg-green">
      <div className="container-sm">
        <h2 className="the-project-heading text-xxl text-center">
          The Project
        </h2>
        <Fade direction="left" triggerOnce={true}>
          <div className="p3-row1">
            <div className="left">
              <h3 className="row-heading">Motivation</h3>
              <p className="row-text">
                The project aimed to integrate digital experiences with the
                existing visitor experience at the Statue Park, surrounded by
                protected nature.
              </p>
            </div>
            <div className="right">
              <img src="images/tegner2.jpg" alt="tegner2" />
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p3-row2">
            <div className="left">
              <img src="images/tegner3.png" alt="tegner3" />
            </div>
            <div className="right">
              <h3 className="row-heading">Iterative prototyping</h3>
              <p className="row-text">
                We developed and tested various prototypes through an iterative
                process, both inside and outside the park.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TheProject3;
