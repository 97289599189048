import React from "react";
import "../KeyLearnings.css";

function KeyLearnings() {
  return (
    <section className="learnings bg-grey2">
      <div className="container-md">
        <h2 className="learnings-header text-xl">Key Learnings</h2>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#1</font>&ensp; Gained experience in balancing
          the needs of visually impaired and sighted users. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#2</font>&nbsp; Developed strategies for user
          engagement through events and social media. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#3</font>&nbsp; Enhanced adaptability and
          management skills in a dynamic startup environment. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#4</font>&nbsp; Conducted in-depth user
          interviews and captured their feedback in articles. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#5</font>&nbsp; Improved graphical design skills
          through visual material creation. <br />
        </p>
      </div>
    </section>
  );
}

export default KeyLearnings;
