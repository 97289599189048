import React from "react";
import "./Work.css";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

function Work() {
  return (
    <section className="work bg-splashpage" id="work">
      <div className="container">
        <Fade direction="down" triggerOnce={true}>
          <h2 className="work-heading text-xxxl text-center" id="work-id">
            <span>Work</span>
          </h2>
        </Fade>
        <div className="work">
          <li className="work-content">
            <Link to="/Inklusiv">
              <div className="work-image">
                <Fade direction="left" triggerOnce={true}>
                  <img src="images/Card1.png" alt="pr-one" />
                </Fade>
              </div>
            </Link>
            <Link to="/Moveunited">
              <div className="work-image">
                <Fade direction="right" triggerOnce={true}>
                  <img src="images/Card2.png" alt="pr-two" />
                </Fade>
              </div>
            </Link>
          </li>

          <div className="work-content">
            <Link to="/Tegner">
              <div className="work-image">
                <Fade direction="left" triggerOnce={true}>
                  <img src="images/Card3.png" alt="pr-three" />
                </Fade>
              </div>
            </Link>
            <Link to="/Kvaelstof">
              <div className="work-image">
                <Fade direction="right" triggerOnce={true}>
                  <img src="images/Card4.png" alt="pr-four" />
                </Fade>
              </div>
            </Link>
          </div>

          <div className="work-content">
            <Link to="/Barnemad">
              <div className="work-image">
                <Fade direction="left" triggerOnce={true}>
                  <img src="images/Card5.png" alt="pr-five" />
                </Fade>
              </div>
            </Link>
            <Link to="/Saetdigindifremtiden">
              <div className="work-image">
                <Fade direction="right" triggerOnce={true}>
                  <img src="images/Card6.png" alt="pr-six" />
                </Fade>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
