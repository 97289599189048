import React from "react";
import "../GraphicDesign.css";
import { Fade } from "react-awesome-reveal";

function GraphicDesign() {
  return (
    <section className="graphic-design">
      <div className="container-md">
        <h2 className="graphic-design-header text-xxl text-center">
          Graphic Design
        </h2>
        <div className="images-gd">
          <Fade cascade damping={0.6} triggerOnce={true}>
            <div>
              <img src="images/inklusiv3.png" width="100%" alt="1" />
            </div>
            <div>
              <img src="images/inklusiv4.png" width="100%" alt="2" />
            </div>
            <div>
              <img src="images/inklusiv5.png" width="100%" alt="3" />
            </div>
            <div>
              <img src="images/inklusiv6.png" width="100%" alt="4" />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default GraphicDesign;
