import React from "react";
import "../KeyLearnings.css";

function KeyLearnings() {
  return (
    <section className="learnings bg-grey2">
      <div className="container-md">
        <h2 className="learnings-header text-xl">Key Learnings</h2>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#1</font>&ensp; Learned to approach sensitive
          topics with empathy to make participants comfortable. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#2</font>&nbsp; Gained insights into designing
          accessible solutions by understanding disability challenges. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#3</font>&nbsp; Developed skills in creating
          visual identities and user interfaces aligned with the brand. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#4</font>&nbsp; Improved communication by
          clearly conveying design concepts to a developer. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#5</font>&nbsp; Enhanced task management and
          adaptability in a fast-paced startup environment. <br />
        </p>
      </div>
    </section>
  );
}

export default KeyLearnings;
