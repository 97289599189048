import React from "react";
import "../KeyLearnings.css";

function KeyLearnings() {
  return (
    <section className="learnings bg-grey2">
      <div className="container-md">
        <h2 className="learnings-header text-xl">Key Learnings</h2>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#1</font>&ensp; Approached the design process
          exploratively and examined alternative research methods. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#2</font>&nbsp; Investigated how affective
          design shapes environmental attitudes. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#3</font>&nbsp; Learned to use sound and
          soundscapes to enhance user experiences. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#4</font>&nbsp; Anchored design in a theoretical
          framework for meaningful outcomes. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#5</font>&nbsp; Explored combining sentiment
          analysis with interviews for deeper emotional insights. <br />
        </p>
      </div>
    </section>
  );
}

export default KeyLearnings;
