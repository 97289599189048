import React from "react";
import "./ConceptVideo.css";

function ConceptVideo() {
  return (
    <section className="graphic-design">
      <div className="container-lg">
        <div className="concept-video">
          <video width="100%" autoPlay loop controls muted>
            <source src="images/tegnerVideo.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
}

export default ConceptVideo;
