import React from "react";
import Hero from "./Projects/Inklusiv/Hero";
import MyRole from "./Projects/Inklusiv/MyRole";
import TheProject1 from "./Projects/Inklusiv/TheProject1";
import GraphicDesign from "./Projects/Inklusiv/GraphicDesign";
import KeyLearnings from "./Projects/Inklusiv/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

function Inklusiv() {
  return (
    <>
      <BackNext
        linkBack="/Saetdigindifremtiden"
        linkNext="/Moveunited"
        back="Sæt dig ind i fremtiden"
        next="Move United"
      />
      <Hero />
      <MyRole />
      <TheProject1 />
      <GraphicDesign />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default Inklusiv;
