import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <Fade direction="down" triggerOnce={true}>
            <h1 className="project-heading text-xxxl text-center">Tegner</h1>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              This master's thesis explores the design of a digital museum
              experience utilizing locative sound to enhance visitor engagement
              at Rudolph Tegner's Statue Park. The project investigates digital
              storytelling and content production aimed at encouraging visitor
              movement, reflection, and meaning-making.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              UX design / User research / Design exploration / Experience
              blending
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-green">
        <div className="container-sm">
          <div className="image-hero">
            <Fade direction="up" triggerOnce={true}>
              <img src="images/tegner1.png" width="80%" alt="mockup-three" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
