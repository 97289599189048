import React from "react";
import "./TheProject6.css";
import { Fade } from "react-awesome-reveal";

function TheProject6() {
  return (
    <>
      <section className="the-project bg-green">
        <div className="container-sm">
          <h2 className="the-project-heading text-xxl text-center">
            The Project
          </h2>
          <Fade direction="left" triggerOnce={true}>
            <div className="p6-row1">
              <div className="left">
                <h3 className="row-heading">Motivation</h3>
                <p className="row-text">
                  The project was motivated by the clearing of Lærkesletten and
                  the construction wall, which symbolizes the frustration with
                  the development where the new urban area, Fælledby, is
                  planned.
                </p>
              </div>
              <div className="right">
                <img src="images/sæt2.png" alt="sæt2" />
              </div>
            </div>
          </Fade>

          <Fade direction="right" triggerOnce={true}>
            <div className="p6-row3">
              <div className="left">
                <img src="images/sæt3.png" alt="sæt3" />
              </div>
              <div className="right">
                <h3 className="row-heading">A simple interactive probe</h3>
                <p className="row-text">
                  The interactive bench “Sæt dig ind i fremtiden” transitions
                  from natural sounds to urban noise over five minutes,
                  prompting reflection on urban development's impact on nature.
                </p>
              </div>
            </div>
          </Fade>

          <Fade direction="left" triggerOnce={true}>
            <div className="p6-row2">
              <div className="left">
                <h3 className="row-heading">
                  Exploring affective data collection methods
                </h3>
                <p className="row-text">
                  We conducted interviews on Amager Fælled, and filmed the
                  participants using Face Emotion Recognizer (FER). This enabled
                  us to analyze facial expressions, comparing verbal statements
                  with emotional data.
                </p>
              </div>
              <div className="right">
                <h3 className="row-heading">
                  Environmental Behavior and Design
                </h3>
                <p className="row-text">
                  The design examined how interactive experiences can impact
                  environmental behavior and foster conservation. By
                  incorporating Guattari's three ecologies, the project aimed to
                  reassess our connection with nature amid urban expansion.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <div className="container-md">
        <div className="mid-pic">
          <img src="images/sæt4.png" alt="sæt4" className="center" />
        </div>
      </div>
    </>
  );
}

export default TheProject6;
