import React from "react";
import "../KeyLearnings.css";

function KeyLearnings() {
  return (
    <section className="learnings bg-grey2">
      <div className="container-md">
        <h2 className="learnings-header text-xl">Key Learnings</h2>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#1</font>&ensp; Created hand-drawn illustrations
          and icons for visual appeal. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#2</font>&nbsp; Incorporated gamification to
          enhance engagement. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#3</font>&nbsp; Designed specifically for
          children and families, simplifying features. <br />
        </p>
        <p className="learnings-text text-sm">
          <font color="#5C95FF">#4</font>&nbsp; Used Figma to prototype and
          iterate designs based on feedback. <br />
        </p>
      </div>
    </section>
  );
}

export default KeyLearnings;
