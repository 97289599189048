import Hero from "./Projects/Tegner/Hero";
import MyRole from "./Projects/Tegner/MyRole";
import TheProject from "./Projects/Tegner/TheProject3";
import ConceptVideo from "./Projects/Tegner/ConceptVideo";
import TheProject3Continued from "./Projects/Tegner/TheProject3Continued";
import KeyLearnings from "./Projects/Tegner/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

import React from "react";

function Tegner() {
  return (
    <>
      <BackNext
        linkBack="/Moveunited"
        linkNext="/Kvaelstof"
        back="Move United"
        next="KVÆLstof"
      />
      <Hero />
      <MyRole />
      <TheProject />
      <ConceptVideo />
      <TheProject3Continued />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default Tegner;
