import React from "react";
import "../MyRole.css";

function MyRole() {
  return (
    <section className="role">
      <div className="container-sm">
        <div className="role-content">
          <h2 className="role-heading text-xxl text-center">My Role</h2>
          <p className="role-text">
            I contributed to an interaction critique of Vild Mad, the conduction
            of user research and tests, and development of the prototype. I
            additionally created the illustrations and hand-drawn icons.
          </p>
          <p className="project-tags">Credits to my study group</p>
        </div>
      </div>
    </section>
  );
}

export default MyRole;
