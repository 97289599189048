import React from "react";
import "../MyRole.css";

function MyRole() {
  return (
    <section className="role">
      <div className="container-sm">
        <div className="role-content">
          <h2 className="role-heading text-xxl text-center">My Role</h2>
          <p className="role-text">
            I contributed to the explorative embodied design process, conducted
            user tests at a demo event, and communicated the concept through
            posters.
          </p>
          <p className="project-tags">Credits to my study group</p>
        </div>
      </div>
    </section>
  );
}

export default MyRole;
