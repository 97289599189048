import "./App.css";
import HomePage from "./pages/HomePage";
import Inklusiv from "./pages/Inklusiv";
import MoveUnited from "./pages/MoveUnited";
import Tegner from "./pages/Tegner";
import KVÆLstof from "./pages/KVÆLstof";
import Barnemad from "./pages/Barnemad";
import Sætdigindifremtiden from "./pages/Sætdigindifremtiden";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Inklusiv" element={<Inklusiv />} />
        <Route path="/Moveunited" element={<MoveUnited />} />
        <Route path="/Tegner" element={<Tegner />} />
        <Route path="/Kvaelstof" element={<KVÆLstof />} />
        <Route path="/Barnemad" element={<Barnemad />} />
        <Route path="/Saetdigindifremtiden" element={<Sætdigindifremtiden />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
