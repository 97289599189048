import React from "react";
import "./About.css";
import { Fade } from "react-awesome-reveal";

function About() {
  return (
    <>
      <div className="about" id="about">
        <div className="container-sm">
          <div className="about-content">
            <Fade direction="down" triggerOnce={true}>
              <h2 className="about-heading text-xxl text-center" id="about-id">
                About
              </h2>
            </Fade>

            <Fade direction="left" triggerOnce={true}>
              <p className="about-text1">
                I'm Zoe, a digital designer passionate about creating intuitive,
                inclusive, and aesthetically pleasing experiences. I'm a
                cand.it. who specialized in UX design at the IT University of
                Copenhagen, where I developed expertise in crafting engaging
                digital experiences. I focus on creating designs that naturally
                fit into our lives, respecting the balance between our inner
                worlds, social interactions, and surrounding environments.
                Design should enhance our natural behaviour, not force us to
                change who we are.
              </p>
            </Fade>

            <Fade direction="right" triggerOnce={true}>
              <p className="about-text2">
                I'm passionate about visual communication, and I love exploring
                art, illustration, and design to create work that feels both
                meaningful and functional. I'm constantly striving to grow as a
                designer, always inspired by my surroundings and the people I
                meet. My goal is to combine technology with our natural rhythms
                to create designs that are both beautiful and that blend into
                our daily lives.
              </p>
            </Fade>
          </div>
        </div>
        <div className="container-sm">
          <div className="about-images">
            <div className="about-image">
              <Fade direction="left" triggerOnce={true}>
                <img src="images/cykelhjelm.JPG" alt="about1" />
              </Fade>
            </div>
            <div className="about-image">
              <Fade direction="right" triggerOnce={true}>
                <img src="images/peace.JPG" alt="about2" />
              </Fade>
            </div>
          </div>
        </div>
        <div className="container-xs">
          <h2 className="contact-heading text-lg " id="contact-id">
            Contact
          </h2>
          <label className="email text-sm">
            <b>E-mail: </b>
          </label>
          <a className="contact text-sm" href="mailto:zbnattestad@gmail.com">
            zbnattestad@gmail.com
          </a>
          <br />
          <label className="phone text-sm">
            <b>Phone: </b>
          </label>
          <a className="contact text-sm" href="tel:+4531379523">
            {" "}
            +45 31 37 95 23
          </a>
        </div>
      </div>
    </>
  );
}

export default About;
