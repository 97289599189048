import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <h1 className="project-heading text-xxxl text-center">
            <Fade direction="down" triggerOnce={true}>
              Barnemad
            </Fade>
          </h1>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              Barnemad is a redesign of the foraging mobile app Vild Mad.
              Utilising photo recognition, Barnemad is aimed at children and
              families, and encourages a playful and curious approach to nature.
              The prototype was developed as part of the course 'Advanced Design
              Processes'.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              UX/UI design / Redesign / Gamification
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-beige">
        <div className="container-sm">
          <div className="image-hero">
            <Fade direction="up" triggerOnce={true}>
              <img src="images/barn1.png" alt="mockup-five" width="88%" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
