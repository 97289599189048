import "./BackNext.css";
import { Link } from "react-router-dom";

function BackNext(props) {
  return (
    <div className="back-next" id="backnext">
      <div className="container-lg">
        <div className="next text-xs">
          <ul>
            <li>
              <Link to={props.linkBack}>&#8592; {props.back}</Link>
            </li>
          </ul>
        </div>
        <div className="back text-xs">
          <ul>
            <li>
              <a href={props.linkNext}>{props.next} &#8594;</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BackNext;
