import React from "react";
import Copyright from "./Copyright.js";
import SocialMedia from "./SocialMedia";

function Footer() {
  return (
    <div className="footer">
      <SocialMedia />
      <Copyright />
    </div>
  );
}

export default Footer;
