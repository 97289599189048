import React from "react";
import Hero from "./Projects/SætDigIndiFremtiden/Hero";
import MyRole from "./Projects/SætDigIndiFremtiden/MyRole";
import TheProject6 from "./Projects/SætDigIndiFremtiden/TheProject6";
import KeyLearnings from "./Projects/SætDigIndiFremtiden/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

function Sætdigindifremtiden() {
  return (
    <>
      <BackNext
        linkBack="/Barnemad"
        linkNext="/Inklusiv"
        back="Barnemad"
        next="Inklusiv"
      />
      <Hero />
      <MyRole />
      <TheProject6 />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default Sætdigindifremtiden;
