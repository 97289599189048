import React from "react";
import "./Footer.css";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaLinkedin,
} from "react-icons/fa6";
import { IconContext } from "react-icons";

function SocialMedia() {
  const fb = <FaSquareFacebook size="20px" />;
  const ig = <FaSquareInstagram size="20px" color="#333" />;
  const li = <FaLinkedin size="20px" color="#333" />;

  return (
    <div className="social-media">
      <ul>
        <li>
          <a
            href="https://www.instagram.com/zoenattestad/"
            target="_blank"
            rel="noreferrer"
          >
            <IconContext.Provider
              value={{ color: "#333", className: "contactIcon" }}
            >
              {ig}
            </IconContext.Provider>
          </a>
        </li>
        <li>
          <a
            href="https://dk.linkedin.com/in/zoe-nattestad-0760a8189"
            target="_blank"
            rel="noreferrer"
          >
            <IconContext.Provider
              value={{ color: "#333", className: "contactIcon" }}
            >
              {li}
            </IconContext.Provider>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/people/Zoe-Amalie-Bille-Nattestad/100008983575225"
            target="_blank"
            rel="noreferrer"
          >
            <IconContext.Provider
              value={{ color: "#333", className: "contactIcon" }}
            >
              {fb}
            </IconContext.Provider>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialMedia;
