import React from "react";
import Hero from "./Projects/KVÆLstof/Hero";
import MyRole from "./Projects/KVÆLstof/MyRole";
import TheProject4 from "./Projects/KVÆLstof/TheProject4";
import KeyLearnings from "./Projects/KVÆLstof/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

function KVÆLstof() {
  return (
    <>
      <BackNext
        linkBack="/Tegner"
        linkNext="/Barnemad"
        back="Tegner"
        next="Barnemad"
      />
      <Hero />
      <MyRole />
      <TheProject4 />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default KVÆLstof;
