import React from "react";
import "./TheProject4.css";
import { Fade } from "react-awesome-reveal";

function TheProject4() {
  return (
    <section className="the-project bg-yellow">
      <div className="container-sm">
        <h2 className="the-project-heading text-xxl text-center">
          The Project
        </h2>
        <Fade direction="left" triggerOnce={true}>
          <div className="p4-row1">
            <div className="left">
              <h3 className="row-heading">Motivation</h3>
              <p className="row-text">
                The project aimed to translate nitrogen pollution in coastal
                waters into a tangible, bodily experience.
              </p>
            </div>
            <div className="right">
              <img src="images/kvæ2.png" alt="kvæ2" />
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p4-row2">
            <div className="left">
              <img src="images/kvæ3.png" alt="kvæ3" />
            </div>
            <div className="right">
              <h3 className="row-heading">An Immersive Experience</h3>
              <p className="row-text">
                “KVÆLstof” provides a 60-second interactive experience
                simulating oxygen depletion using restricted breathing and
                video.
              </p>
            </div>
          </div>
        </Fade>

        <Fade direction="left" triggerOnce={true}>
          <div className="p4-row1">
            <div className="left">
              <h3 className="row-heading">Explorative Design Process</h3>
              <p className="row-text">
                The design process involved somatic interactions using tools
                like straws and masks to explore the effects of oxygen
                depletion.
              </p>
            </div>
            <div className="right">
              <h3 className="row-heading">Testing and Feedback</h3>
              <p className="row-text">
                The prototype was tested at a demo event, where participants had
                strong emotional reactions, providing valuable feedback on the
                experience.
              </p>
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p4-row3">
            <div className="left">
              <img src="images/kvæ4.png" alt="kvæ4" />
            </div>
            <div className="right">
              <img src="images/kvæ5.png" alt="kvæ5" />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TheProject4;
