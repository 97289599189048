import React from "react";
import "./Footer.css";

function Copyright() {
  return (
    <div className="createdwith">
      <div className="container-sm">
        <p className="createdwith-text text-sm">
          Created with <font color="#9DBFFF">&hearts;</font> <br />
          Zoe Nattestad <font color="#9DBFFF">&#64;</font> Copyright 2024
        </p>
      </div>
    </div>
  );
}

export default Copyright;
