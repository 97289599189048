import React from "react";
import Hero from "./Projects/Barnemad/Hero";
import MyRole from "./Projects/Barnemad/MyRole";
import TheProject5 from "./Projects/Barnemad/TheProject5";
import KeyLearnings from "./Projects/Barnemad/KeyLearnings";
import BackNext from "../components/BackNext/BackNext";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

function Barnemad() {
  return (
    <>
      <BackNext
        linkBack="/Kvaelstof"
        linkNext="/Saetdigindifremtiden"
        back="KVÆLstof"
        next="Sæt dig ind i fremtiden"
      />
      <Hero />
      <MyRole />
      <TheProject5 />
      <KeyLearnings />
      <ScrollToTopButton />
    </>
  );
}

export default Barnemad;
