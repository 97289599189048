import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <h1 className="project-heading text-xxxl text-center">
            <Fade direction="down" triggerOnce={true}>
              Sæt dig ind i fremtiden
            </Fade>
          </h1>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              The bachelor project explores Copenhageners' affective
              relationship with Amager Fælleds nature, through explorative data
              collection and the development of an interactive bench. The sound
              based experience, 'Sæt dig ind i fremtiden', creates room for
              reflection and directs human attention towards nature
              conservation.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              Affective interactions / Environmental awareness / Design
              exploration
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-green">
        <div className="container-sm">
          <div className="image-hero">
            <Fade direction="up" triggerOnce={true}>
              <img src="images/sæt1.png" alt="mockup-six" width="70%" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
