import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <Fade direction="down" triggerOnce={true}>
            <h1 className="project-heading text-xxxl text-center">KVÆLstof</h1>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              KVÆLstof is an interactive experience that allows you to feel the
              consequences of oxygen depletion in Danish coastal waters on your
              own body, developed as part of the course 'Designing Aesthetic
              User Experiences'.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              Aesthetic experiences / Environmental awareness / Embodied
              interactions
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-yellow">
        <div className="container-sm">
          <div className="image-hero">
            <Fade direction="up" triggerOnce={true}>
              <img src="images/kvæ1.png" width="60%" alt="mockup-four" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
