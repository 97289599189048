import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <Fade direction="down" triggerOnce={true}>
            <h1 className="project-heading text-xxxl text-center">
              Move United
            </h1>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              I worked as a student assistant in the startup company Move
              United. Move United is a mobile app and online community that
              connects blind and visually impaired people with sighted guides
              for shared outdoor experiences and pshyical activites.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              UX design / UI design / User research / Communication / SoMe
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-beige">
        <div className="container-sm">
          <div className="image-hero">
            <Fade direction="up" triggerOnce={true}>
              <img src="images/mu1.png" width="65%" alt="mockup-two" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
