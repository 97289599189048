import React from "react";
import "../MyRole.css";

function MyRole() {
  return (
    <section className="role">
      <div className="container-sm">
        <div className="role-content">
          <h2 className="role-heading text-xxl text-center">My Role</h2>
          <p className="role-text">
            I designed the visual identity and user interface in Figma,
            conducted user research and interviews, and created graphic
            materials such as posters, flyers, and slides.
          </p>
        </div>
      </div>
    </section>
  );
}

export default MyRole;
