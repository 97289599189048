import React from "react";
import "../Hero.css";
import { Fade } from "react-awesome-reveal";

function Hero() {
  return (
    <section className="project-hero">
      <div className="container-sm">
        <div className="project-content">
          <h1 className="project-heading text-xxxl text-center">
            <Fade direction="down" triggerOnce={true}>
              Inklusiv.
            </Fade>
          </h1>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-text">
              As a student assistant, I contributed to developing the startup
              company Inklusiv. Inklusiv is a mobile app designed to provide
              inclusive and accessible shopping experiences for people with
              disabilities.
            </p>
          </Fade>
          <Fade direction="down" triggerOnce={true}>
            <p className="project-tags">
              UX design / UI design / User research / Visual Idenity / Graphic
              Design / SoMe
            </p>
          </Fade>
        </div>
      </div>
      <div className="mockups bg-blue">
        <div className="container-sm">
          <div className="images-hero">
            <Fade cascade damping={0.6} triggerOnce={true}>
              <img src="images/ink-mock-1.png" alt="mockup-one" />
              <img src="images/ink-mock-2.png" alt="mockup-two" />
              <img src="images/ink-mock-3.png" alt="mockup-three" />
              <img src="images/ink-mock-4.png" alt="mockup-four" />
              <img src="images/ink-mock-5.png" alt="mockup-five" />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
