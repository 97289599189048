import React from "react";
import "./TheProject3.css";
import { Fade } from "react-awesome-reveal";

function TheProject3Continued() {
  return (
    <section className="the-project bg-green">
      <div className="container-sm">
        <Fade direction="left" triggerOnce={true}>
          <div className="p3-row3">
            <div className="left">
              <h3 className="row-heading">User Interface Experimentation </h3>
              <p className="row-text">
                The essential task was to avoid the “heads-down phenomenon” by
                designing a simple user interface that displayed only the most
                necessary information. One iteration included animations that
                served as metaphors for users' movements.
              </p>
            </div>
            <div className="right">
              <div className="two-gifs">
                <div className="gif1">
                  <img src="images/gif1.gif" width="100%" alt="gif1" />
                </div>
                <div className="gif2">
                  <img src="images/gif2.gif" width="100%" alt="gif2" />
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p3-row2">
            <div className="left">
              <img src="images/tegner4.png" alt="tegner4" />
            </div>
            <div className="right">
              <h3 className="row-heading">Testing With Visitors</h3>
              <p className="row-text">
                A key part of the project was testing the digital experience
                with visitors in the Statue Park to examine the integration of
                technology within the sensitive context.
              </p>
            </div>
          </div>
        </Fade>

        <Fade direction="left" triggerOnce={true}>
          <div className="p3-row1">
            <div className="left">
              <h3 className="row-heading">Creating an experience blend</h3>
              <p className="row-text">
                To evaluate Tegner, we compared the existing experience with the
                new one and assessed how the inclusion of technology impacted
                users' motivation, the physical location, the museum's
                narrative, and users' actions.
              </p>
            </div>
            <div className="right">
              <img src="images/tegner5.png" alt="tegner5" />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TheProject3Continued;
