import React from "react";
import Hero from "./HomePage/Hero";
import Work from "./HomePage/Work";
import About from "./HomePage/About";

function HomePage() {
  return (
    <div className="homepage">
      <Hero />
      <Work />
      <About />
    </div>
  );
}

export default HomePage;
