import React from "react";
import "./TheProject2.css";
import { Fade } from "react-awesome-reveal";

function TheProject2() {
  return (
    <section className="the-project bg-beige">
      <div className="container-sm">
        <h2 className="the-project-heading text-xxl text-center">
          The Project
        </h2>
        <Fade direction="left" triggerOnce={true}>
          <div className="p2-row1">
            <div className="left">
              <h3 className="row-heading">
                Developing an Accessible User Interface
              </h3>
              <p className="row-text">
                Move United focuses on creating an interface accessible to
                visually impaired users while remaining intuitive for sighted
                users.
              </p>
            </div>
            <div className="right">
              <img src="images/mu2.png" alt="mu2" />
            </div>
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="p2-row1">
            <div className="left">
              <h3 className="row-heading">Redesign</h3>
              <p className="row-text">
                I redesigned the logo and visual identity, incorporating
                gamification elements to encourage user engagement.
              </p>
            </div>
            <div className="right">
              <img src="images/mu3.png" alt="mu3" width="100%" />
            </div>
          </div>
        </Fade>

        <Fade direction="left" triggerOnce={true}>
          <div className="p2-row1">
            <div className="left">
              <h3 className="row-heading">Creating Community</h3>
              <p className="row-text">
                Fostering community through events, social media, and ongoing
                dialogue with users was a key aspect of my role.
              </p>
            </div>
            <div className="right">
              <img src="images/mu4.png" alt="mu4" />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TheProject2;
