import React from "react";
import "./TheProject5.css";
import { Fade } from "react-awesome-reveal";

function TheProject5() {
  return (
    <>
      <section className="the-project bg-beige">
        <div className="container-sm">
          <h2 className="the-project-heading text-xxl text-center">
            The Project
          </h2>
          <Fade direction="left" triggerOnce={true}>
            <div className="p5-row1">
              <div className="left">
                <h3 className="row-heading">Design Motivation</h3>
                <p className="row-text">
                  Barnemad addressed Vild Mad's unrealistic expectations of
                  users' skills and knowledge, simplifying features for children
                  and families.
                </p>
              </div>
              <div className="right">
                <img src="images/barn2.png" alt="kvæ2" />
              </div>
            </div>
          </Fade>

          <Fade direction="right" triggerOnce={true}>
            <div className="p5-row2">
              <div className="left">
                <h3 className="row-heading">Targeted Redesign</h3>
                <p className="row-text">
                  Barnemad targets children and families, simplifying features
                  to better suit their needs. The prototype was built in Figma
                  and tested with users.
                </p>
              </div>
              <div className="right">
                <h3 className="row-heading">Gamification</h3>
                <p className="row-text">
                  We added gamification and playful elements to Barnemad to make
                  it engaging and accessible for children.
                </p>
              </div>
            </div>
          </Fade>

          <Fade direction="left" triggerOnce={true}>
            <div className="p5-row3">
              <div className="left">
                <img src="images/barn4.png" alt="barn4" />
              </div>
              <div className="right">
                <h3 className="row-heading">Long-Term Impact</h3>
                <p className="row-text">
                  The redesign aims to build a lasting connection between
                  children and nature, promoting environmental awareness through
                  playful interaction.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <div className="container-sm">
        <div className="mid-pic">
          <img src="images/barn3.png" alt="barn3" className="center" />
        </div>
      </div>
    </>
  );
}

export default TheProject5;
