import React from "react";
import "./ScrollToTopButton.css"; // Import the CSS file

function ScrollToTopButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="button-container">
      <button
        className="scrollToTop"
        onClick={scrollToTop}
        id="myBtn"
        title="Go to top"
      >
        ↑
      </button>
    </div>
  );
}

export default ScrollToTopButton;
